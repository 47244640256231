$('#timelineHistory').horizontalTimeline();

$(".selection-2").select2({
    minimumResultsForSearch: 20,
    dropdownParent: $('#dropDownSelect1')
});

$('#play-testimonial').on('click', function (e) {
    e.preventDefault();
    $('#video-overlay').addClass('open');
    $("#video-overlay").append('<iframe width="70%" height="500" src="https://www.youtube.com/embed/ngElkyQ6Rhs" allowfullscreen></iframe>');
});

$('.video-overlay, .video-overlay-close').on('click', function (e) {
    e.preventDefault();
    close_video();
});

$(document).keyup(function (e) {
    if (e.keyCode === 27) {
        close_video();
    }
});

function close_video() {
    $('.video-overlay.open').removeClass('open').find('iframe').remove();
}

$('#customers-testimonials').owlCarousel({
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots:true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 2
        },
        1170: {
            items: 5
        }
    }
});

$('#courses-intro').owlCarousel({
    loop: true,
    center: true,
    items: 3,
    margin: 5,
    autoplay: true,
    dots:true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 2
        },
        1170: {
            items: 3
        }
    }
});

$('.cellphone').mask('(00) 00000-0000', {placeholder: "(00) 00000-0000"});

//playVideoTestimonial
$('.play-testimonial').on('click', function (e) {
    e.preventDefault();
    link = $(this).data('link');
    route = $(this).data('route');
    $('#video-overlay').addClass('open');
    $("#video-overlay").append('<iframe width="70%" height="500" src="https://www.youtube.com/embed/"' + link + ' allowfullscreen></iframe>');

    $.ajax({
        url: route,
        type: "get",
        dataType: "json",
        success: function (response) {

        }
    });
});
